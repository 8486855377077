<template>
  <div>
    <b-row>
      <b-col
        :md="7"
        class="mt-1 d-flex flex-wrap flex-row"
      >
        <x-date-picker
          v-model="filterModel.from_date"
          :placeholder="$t('Дата начала')"
          class="mr-1 mb-1"
        />
        <x-date-picker
          v-model="filterModel.to_date"
          :placeholder="$t('Дата окончания')"
          class="mr-1 mb-1"
        />
      </b-col>
      <b-col
        :md="5"
        class="mt-1"
      >
        <b-button
          v-can="'contracts-excel-download'"
          variant="success"
          class="btn-tour-skip text-right mb-2 float-right"
          :disabled="xlLoading"
          @click="tableToExcel"
        >
          <feather-icon
            v-if="xlLoading"
            icon="LoaderIcon"
            size="16"
          />
          <feather-icon
            v-else
            icon="DownloadIcon"
            size="16"
          />
          Юклаб олиш
        </b-button>
      </b-col>
    </b-row>
    <b-overlay :show="loading">
      <good-table-column-search
        v-if="contracts"
        :items="contracts.data"
        :columns="columns"
        :total="contracts.total"
        :page="page"
        :has-show="true"
        model="AutopayClient"
        :filter="filterModel"
        @getItems="getItems"
        @onPageChange="(p) => (page = p)"
      >
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'actionPaymentClient'">
            <b-button
              variant="outline-primary"
              class="btn-tour-skip text-right mb-2 float-right"
              @click="showClient(props.row.id)"
            >
              Batafsil
            </b-button>
          </span>
        </template>
      </good-table-column-search>
      <template v-if="xlContracts && xlContracts.data">
        <GoodTableExcel
          v-show="false"
          ref="table"
          class="tablee"
          :items="xlContracts.data"
          :columns="xlColumns"
          :total="xlContracts.total"
        />
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'
import GoodTableExcel from '@/views/table/vue-good-table/GoodTableExcel.vue'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch,
    GoodTableExcel,
  },
  data() {
    return {
      page: 1,
      loading: false,
      xlLoading: false,
      statusTab: 1,
      filterModel: {
        per_page: 50,
        from_date: null,
        to_date: null,
        relations: 'uniAccessClient',
      },
      columns: [],
      contracts: null,
      xlContracts: null,
      //
      uri: 'data:application/vnd.ms-excel;base64,',
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta charset="utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64(s) {
        return window.btoa(unescape(encodeURIComponent(s)))
      },
      format(s, c) {
        return s.replace(/{(\w+)}/g, (m, p) => c[p])
      },
    }
  },
  computed: {
    title() {
      return 'Autopay mijozlar'
    },
    xlColumns() {
      return this.firstCols
    },
    firstCols() {
      return [
        {
          label: this.$t('ПИНФЛ'),
          field: 'pin',
          showField: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Лоан ИД'),
          field: 'loan_id',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Пасспорт ID'),
          field: 'uni_access_client.passport',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('ФИО'),
          field: this.fullnameField,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Сумма долга'),
          field: 'total_debt',
          isAmount: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Остаток долга'),
          field: 'current_debt',
          isDangerAmount: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Оплаченная сумма'),
          field: 'paid_amount',
          isSuccessAmount: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Действия'),
          field: 'actionPaymentClient',
        },
      ]
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.from_date': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.to_date': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    if (this.isLevier) {
      this.filterModel.status = 1
      this.statusTab = 3
      this.filterModel.is_overdue = true
    }
    if (this.$route.query && this.$route.query.client_id) {
      this.filterModel.client_id = this.$route.query.client_id
    }
    this.filterModel.to_date = this.today()

    this.getItems()
    this.getColumns()
  },
  methods: {
    ...mapActions({
      getItemsAction: 'resource/getAutopayContracts',
    }),
    showClient(id) {
      this.goTo('AutopayClient-show', { id })
    },
    getColumns() {
      this.columns = [...this.firstCols]
    },
    fullnameField(row) {
      return [row.uni_access_client.lastname, row.uni_access_client.firstname, row.uni_access_client.middlename].join(' ')
    },
    passportField(row) {
      if (row.client && row.client.doc_series) {
        return row.client.doc_series + row.client.doc_number
      }
      return null
    },

    getItems() {
      this.loading = true
      this.getItemsAction({ ...this.filterModel, page: this.page })
        .then(res => {
          this.contracts = res.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getXlItems() {
      this.xlLoading = true
      await this.getItemsAction({
        ...this.filterModel,
        per_page: 9999,
        page: this.page,
      })
        .then(res => {
          this.xlContracts = res.data
        })
        .finally(() => {
          this.xlLoading = false
        })
    },
    today() {
      const today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      const yyyy = today.getFullYear()
      return `${yyyy}-${mm}-${dd}`
    },
    async tableToExcel(table) {
      await this.getXlItems()

      const tableeee = document.querySelector(
        'div.tablee div.vgt-responsive table',
      )
      const secondRow = tableeee.querySelector('thead tr:nth-child(2)')
      if (secondRow) {
        console.log(secondRow)
        secondRow.remove()
      }
      const rows = tableeee.querySelectorAll('table tr')
      rows.forEach(row => {
        const lastCell = row.lastElementChild
        // const firstCell = row.firstElementChild
        // firstCell.remove()
        if (lastCell.querySelector('td.vgt-left-align svg')) {
          lastCell.remove()
        }
      })
      setTimeout(() => {
        if (!table.nodeType) table = tableeee
        const ctx = { worksheet: `${this.title}`, table: table.innerHTML }
        const link = document.createElement('a')
        let title = `${this.title}(${this.formatDateYmd(
          this.today(),
          'DD.MM.YYYY',
        )})`
        if (this.filterModel.from_date) {
          title = `${this.title} (${this.formatDateYmd(
            this.filterModel.from_date,
            'DD.MM.YYYY',
          )}-${this.formatDateYmd(
            this.filterModel.to_date,
            'DD.MM.YYYY',
          )})`
        }
        link.download = `${title}.xls`
        link.href = this.uri + this.base64(this.format(this.template, ctx))
        link.click()
      }, 10)
    },
  },
}
</script>

<style scoped></style>
